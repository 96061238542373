import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { MDXLayout as PageLayout } from "../../components/blocks/mdx-layout";
import { SEO } from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Art" description="Besides software development I have another huge passion of mine: Designing UI in Figma, creating photo manipulations in Photoshop, tinkering with abstract art in Cinema4D, and exploring nature & capturing it with my Fujifilm camera. Or in other words: I enjoy art!" breadcrumbListItems={[{
      name: `Art`,
      url: `/art`
    }]} mdxType="SEO" />

    <h1 {...{
      "id": "art"
    }}>{`Art`}</h1>
    <p>{`Besides software development I have another huge passion of mine: Designing UI in Figma, creating photo manipulations in Photoshop, tinkering with abstract art in Cinema4D, and exploring nature & capturing it with my Fujifilm camera. Or in other words: `}<em parentName="p">{`I enjoy art!`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8c9f6bcf087d5e2c29625e6e7e1e1d82/bb9a8/art-cover-image.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQAE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAVDNoYjYZP/EABoQAAMBAAMAAAAAAAAAAAAAAAECAwAEEzP/2gAIAQEAAQUCoQqLSYHcNyvCeZjv/8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQMBAT8BS//EABURAQEAAAAAAAAAAAAAAAAAAAAS/9oACAECAQE/AVP/xAAWEAEBAQAAAAAAAAAAAAAAAAAAATH/2gAIAQEABj8CtuNYqtf/xAAcEAEAAQQDAAAAAAAAAAAAAAABABARITFhgbH/2gAIAQEAAT8hUjAyxWlzfXEQdXuh8IFLLU//2gAMAwEAAgADAAAAEJv/AP/EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QuQSH/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/ECXt/8QAHRAAAgICAwEAAAAAAAAAAAAAAREAITFBYaGx4f/aAAgBAQABPxDWHFb1iMSZogaI1gRQHOHzD6/ZmLVjUCAATc//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The image is divided in three equal parts. It shows on the left a preview of a webdesign (a Figma screenshot of the design for themes.lekoarts.de). In the middle a 3D generated purple hand reaching out of the water (the hand is broken into pieces at the bottom and some float on the water) with a sunset sky. And on the right a photo of the coast in San Francisco at the \"Suthro Baths\".",
            "title": "The image is divided in three equal parts. It shows on the left a preview of a webdesign (a Figma screenshot of the design for themes.lekoarts.de). In the middle a 3D generated purple hand reaching out of the water (the hand is broken into pieces at the bottom and some float on the water) with a sunset sky. And on the right a photo of the coast in San Francisco at the \"Suthro Baths\".",
            "src": "/static/8c9f6bcf087d5e2c29625e6e7e1e1d82/87945/art-cover-image.jpg",
            "srcSet": ["/static/8c9f6bcf087d5e2c29625e6e7e1e1d82/b95e4/art-cover-image.jpg 256w", "/static/8c9f6bcf087d5e2c29625e6e7e1e1d82/1779b/art-cover-image.jpg 512w", "/static/8c9f6bcf087d5e2c29625e6e7e1e1d82/87945/art-cover-image.jpg 1024w", "/static/8c9f6bcf087d5e2c29625e6e7e1e1d82/6afe2/art-cover-image.jpg 1536w", "/static/8c9f6bcf087d5e2c29625e6e7e1e1d82/7a411/art-cover-image.jpg 2048w", "/static/8c9f6bcf087d5e2c29625e6e7e1e1d82/bb9a8/art-cover-image.jpg 2560w"],
            "sizes": "(max-width: 1024px) 100vw, 1024px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`I’ve started my journey in High School as I wanted to have custom signatures (little graphics under your post) in a couple of online forums. There was and still is a community of people doing these little `}<em parentName="p">{`tags`}</em>{` on `}<a parentName="p" {...{
        "href": "https://www.deviantart.com/"
      }}>{`DeviantArt`}</a>{` and it was my entry to the world of using Photoshop. Like a sponge I absorbed every tutorial there was online and probably used it daily for a couple of years. Soon I began doing webdesign (in Photoshop, I know 😀) and photo manipulations — the former for some extra money, the latter as it was more advanced and really challenged all my acquired skills. It’s both difficult & rewarding blending multiple photos seamlessly together into a picture while telling a story via the image itself. Over the years I learned new tools and shifted some work out of Photoshop and e.g. to Figma. Nowadays I do UI design for all my own projects (it also helps for frontend development in my day job) and the rest as a hobby in my free time.`}</p>
    <p>{`Under the `}<a parentName="p" {...{
        "href": "/art/design"
      }}>{`Design`}</a>{` category you’ll primarily find compositings, webdesigns & related graphics made in Figma. The `}<a parentName="p" {...{
        "href": "/art/3d"
      }}>{`3D Art`}</a>{` section features my favourite art made in various 3D software. Last but not least, the `}<a parentName="p" {...{
        "href": "/art/photography"
      }}>{`Photography`}</a>{` page shows the photos (primarily landscapes) I’m most proud of!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      